import { APICollectionListWithNFT } from '@betablocks/shared/lib/api/collections'
import { APINFT, APITagListWithNFT } from '@betablocks/shared/lib/api/nfts'
import { PageLayoutBlockCarousel } from '@betablocks/shared/lib/api/pages'
import ProductCarouselBlock from '../../Blocks/ProductCarouselBlock'

type Props = {
  data: PageLayoutBlockCarousel['data']
  nfts: APINFT[]
  nftsByCollection: APICollectionListWithNFT
  nftsByTag: APITagListWithNFT
}

const RenderProductCarouselBlock: React.FC<Props> = ({
  data,
  nfts,
  nftsByCollection,
  nftsByTag,
}) => {
  const { title, category, tags } = data

  let products: APINFT[] = []

  if (category) {
    products =
      nftsByCollection
        .find((collection) => collection.id.toString() === category.toString())
        ?.nfts.map((nft) => nfts.find((nft2) => nft2.id === nft)) || []
  } else {
    products =
      Array.from(
        new Set(
          nftsByTag
            .filter((tag) => tags.includes(tag.name))
            .map((tag) => tag.nfts)
            .flat()
        )
      ).map((nftId) => nfts.find((nft) => nft.id === nftId)) || []
  }

  // Do not render carousel if no products will be shown anyway.
  if (products.length === 0) {
    return null
  }

  return (
    <ProductCarouselBlock
      title={title}
      products={products}
      collectionId={data['category']}
      tags={tags}
      span={data.span ?? 12}
    />
  )
}

export default RenderProductCarouselBlock
