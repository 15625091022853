import { PageLayoutBlockImage } from '@betablocks/shared/lib/api/pages'
import ImageElement from '../../ImageElement'

type Props = {
  data: PageLayoutBlockImage['data']
}

const RenderImageBlock: React.FC<Props> = ({ data }) => {
  return <ImageElement data={data} />
}

export default RenderImageBlock
