import { APICollectionListWithNFT } from '@betablocks/shared/lib/api/collections'
import { APINFT, APITagListWithNFT } from '@betablocks/shared/lib/api/nfts'
import { PageLayoutBlock, PageLayoutData } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import React from 'react'
import RenderButtonBlock from '../../BlockRenderers/RenderButtonBlock'
import RenderCountdownBlock from '../../BlockRenderers/RenderCountdownBlock'
import RenderHeroBlock from '../../BlockRenderers/RenderHeroBlock'
import RenderImageBlock from '../../BlockRenderers/RenderImageBlock'
import RenderProductCarouselBlock from '../../BlockRenderers/RenderProductCarouselBlock'
import RenderProductSpotlightBlock from '../../BlockRenderers/RenderProductSpotlightBlock'
import RenderTextElementBlock from '../../BlockRenderers/RenderTextElementBlock'
import RenderVideoBlock from '../../BlockRenderers/RenderVideoBlock'

type Props = {
  data: PageLayoutData
  nfts: APINFT[]
  nftsByCollection: APICollectionListWithNFT
  nftsByTag: APITagListWithNFT
}

const mapSpanToColSpan = (span: number): string => {
  switch (span) {
    case 1:
      return 'col-span-1 md:col-span-1 lg:col-span-1'
    case 2:
      return 'col-span-1 md:col-span-1 lg:col-span-2'
    case 3:
      return 'col-span-1 md:col-span-1 lg:col-span-3'
    case 4:
      return 'col-span-1 md:col-span-2 lg:col-span-4'
    case 5:
      return 'col-span-1 md:col-span-2 lg:col-span-5'
    case 6:
      return 'col-span-2 md:col-span-2 lg:col-span-6'
    case 7:
      return 'col-span-2 md:col-span-3 lg:col-span-7'
    case 8:
      return 'col-span-2 md:col-span-3 lg:col-span-8'
    case 9:
      return 'col-span-2 md:col-span-3 lg:col-span-9'
    case 10:
      return 'col-span-2 md:col-span-4 lg:col-span-10'
    case 11:
      return 'col-span-2 md:col-span-4 lg:col-span-11'
    case 12:
      return 'col-span-2 md:col-span-4 lg:col-span-12'
    default:
      return 'col-span-1 md:col-span-1 lg:col-span-1'
  }
}

const RenderBlocks: React.FC<Props> = ({ data, nfts, nftsByCollection, nftsByTag }) => {
  const renderColumn = (
    block: PageLayoutBlock,
    index: number,
    arr: PageLayoutBlock[]
  ): JSX.Element => {
    // Align spotlight blocks with carousel blocks on the same row.
    const hasCarousel = arr.find((b) => b.type === 'carousel') ? 'mt-[75px]' : undefined

    switch (block.type) {
      case 'hero':
        return (
          <div key={index} className={mapSpanToColSpan(12)}>
            <RenderHeroBlock data={block.data} nfts={nfts} />
          </div>
        )
      case 'product': {
        return (
          <div key={index} className={classNames(hasCarousel, mapSpanToColSpan(3))}>
            <RenderProductSpotlightBlock data={block.data} nfts={nfts} />
          </div>
        )
      }
      case 'image': {
        return (
          <div
            key={index}
            className={classNames(hasCarousel, mapSpanToColSpan(block.data.span ?? 3))}
          >
            <RenderImageBlock data={block.data} />
          </div>
        )
      }
      case 'video': {
        return (
          <div
            key={index}
            className={classNames(hasCarousel, mapSpanToColSpan(block.data.span ?? 12))}
          >
            <RenderVideoBlock data={block.data} />
          </div>
        )
      }
      case 'text':
        // Text blocks do not need adjustment when there is a carousel block on the same row.
        return (
          <div key={index} className={mapSpanToColSpan(block.data.span ?? 12)}>
            <RenderTextElementBlock data={block.data} />
          </div>
        )
      case 'button':
        return (
          <div
            key={index}
            className={classNames(hasCarousel, mapSpanToColSpan(block.data.span ?? 12))}
          >
            <RenderButtonBlock data={block.data} />
          </div>
        )
      case 'spacer':
        return (
          <div
            key={index}
            className={classNames(hasCarousel, mapSpanToColSpan(block.data.span ?? 12))}
          />
        )
      case 'carousel':
        return (
          <div
            key={index}
            className={classNames('carousel', mapSpanToColSpan(block.data.span ?? 12))}
          >
            <RenderProductCarouselBlock
              data={block.data}
              nfts={nfts}
              nftsByCollection={nftsByCollection}
              nftsByTag={nftsByTag}
            />
          </div>
        )
      case 'countdown':
        return (
          <div key={index} className={mapSpanToColSpan(3)}>
            <RenderCountdownBlock data={block.data} />
          </div>
        )
    }
  }

  const padIfNotHero = (isHeroRow: boolean, index: number, content: JSX.Element): JSX.Element =>
    isHeroRow ? (
      content
    ) : (
      <div key={index} className="container px-4 lg:px-32 py-4">
        {content}
      </div>
    )

  const renderRow = (block: PageLayoutBlock, index: number): React.ReactNode => {
    const isHeroRow = block.type === 'columns' && block.data.columns[0].type === 'hero'

    switch (block.type) {
      case 'columns': {
        return padIfNotHero(
          isHeroRow,
          index,
          <div
            key={index}
            className={classNames(
              'grid',
              [
                'grid-cols-2',
                'md:grid-cols-[repeat(4,minmax(0,18rem))]',
                'lg:grid-cols-[repeat(12,minmax(0,18rem))]',
              ],
              'mb-8',
              'gap-y-8',
              'gap-x-4 md:gap-x-8'
            )}
          >
            {block.data.columns.map(renderColumn)}
          </div>
        )
      }
    }
  }
  return <>{data.blocks.map(renderRow)}</>
}

export default RenderBlocks
