import { PageLayoutBlockButton } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import ButtonBlock from '../../Blocks/ButtonBlock'

type Props = {
  data: PageLayoutBlockButton['data']
}

const RenderButtonBlock: React.FC<Props> = ({ data }) => {
  return (
    <div className={classNames('w-full')}>
      <ButtonBlock
        buttonText={data.text}
        buttonLink={data.href}
        buttonTextColor={data.color}
        buttonColor={data.backgroundColor}
      />
    </div>
  )
}

export default RenderButtonBlock
