import { PageLayoutBlockVideo } from '@betablocks/shared/lib/api/pages'
import VideoElement from '../../VideoElement'

type Props = {
  data: PageLayoutBlockVideo['data']
}

const RenderVideoBlock: React.FC<Props> = ({ data }) => {
  return <VideoElement data={data} />
}

export default RenderVideoBlock
