import { APINFT } from '@betablocks/shared/lib/api/nfts'
import { PageLayoutBlockHero } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import ProductSpotlight from '../../ProductSpotlight'
import ButtonBlock from '../ButtonBlock'
import CountdownBlock from '../CountdownBlock'
import TextElementBlock from '../TextElementBlock'

type Props = {
  mainText: PageLayoutBlockHero['data']['text']['data']['title']
  subText: PageLayoutBlockHero['data']['text']['data']['body']
  textSize: PageLayoutBlockHero['data']['text']['data']['size']
  textColor: PageLayoutBlockHero['data']['text']['data']['color']
  textAlign: PageLayoutBlockHero['data']['text']['data']['align']
  textBold: PageLayoutBlockHero['data']['text']['data']['bold']
  textUnderline: PageLayoutBlockHero['data']['text']['data']['underline']
  textStrikeThrough: PageLayoutBlockHero['data']['text']['data']['strikeThrough']
  textLetterSpacing: PageLayoutBlockHero['data']['text']['data']['letterSpacing']
  textLineSpacing: PageLayoutBlockHero['data']['text']['data']['spacing']
  buttonText: PageLayoutBlockHero['data']['button']['data']['text']
  buttonLink: PageLayoutBlockHero['data']['button']['data']['href']
  buttonTextColor: PageLayoutBlockHero['data']['button']['data']['color']
  buttonColor: PageLayoutBlockHero['data']['button']['data']['backgroundColor']
  imageFill: PageLayoutBlockHero['data']['banner']['data']['image']['data']['fill']
  imageAnchor: PageLayoutBlockHero['data']['banner']['data']['image']['data']['anchor']
  imageSpan: PageLayoutBlockHero['data']['banner']['data']['image']['data']['src']
  mobileImageFill: PageLayoutBlockHero['data']['banner']['data']['image']['data']['mobileFill']
  mobileImageAnchor: PageLayoutBlockHero['data']['banner']['data']['image']['data']['mobileAnchor']
  mobileImageSpan: PageLayoutBlockHero['data']['banner']['data']['image']['data']['mobileSrc']
  backgroundColor: PageLayoutBlockHero['data']['banner']['data']['image']['data']['backgroundColor']
  contentAlignment: PageLayoutBlockHero['data']['banner']['data']['image']['data']['contentAlignment']
  paddingTop: PageLayoutBlockHero['data']['banner']['data']['image']['data']['paddingTop']
  paddingRight: PageLayoutBlockHero['data']['banner']['data']['image']['data']['paddingRight']
  paddingBottom: PageLayoutBlockHero['data']['banner']['data']['image']['data']['paddingBottom']
  paddingLeft: PageLayoutBlockHero['data']['banner']['data']['image']['data']['paddingLeft']
  className?: string
  showProduct?: PageLayoutBlockHero['data']['banner']['data']['product']['data']['showProduct']
  productFill?: PageLayoutBlockHero['data']['banner']['data']['product']['data']['fill']
  productAnchor?: PageLayoutBlockHero['data']['banner']['data']['product']['data']['anchor']
  productSpan?: PageLayoutBlockHero['data']['banner']['data']['product']['data']['imageOverrideSrc']
  showCountdown?: PageLayoutBlockHero['data']['banner']['data']['countdown']['data']['showCountdown']
  showDate?: PageLayoutBlockHero['data']['banner']['data']['countdown']['data']['showDate']
  date?: PageLayoutBlockHero['data']['banner']['data']['countdown']['data']['date']
  primaryColor?: PageLayoutBlockHero['data']['banner']['data']['countdown']['data']['primaryColor']
  secondaryColor?: PageLayoutBlockHero['data']['banner']['data']['countdown']['data']['secondaryColor']
  countdownStyle?: PageLayoutBlockHero['data']['banner']['data']['countdown']['data']['style']
  countdownSpacing?: PageLayoutBlockHero['data']['banner']['data']['countdown']['data']['spacing']
  countdownLetterSpacing?: PageLayoutBlockHero['data']['banner']['data']['countdown']['data']['letterSpacing']
  countdownSize?: PageLayoutBlockHero['data']['banner']['data']['countdown']['data']['letterSpacing']
  nft?: APINFT
}

const HeroBlock: React.FC<Props> = ({
  mainText,
  subText,
  textSize,
  textColor,
  textAlign,
  textBold,
  textUnderline,
  textStrikeThrough,
  textLetterSpacing,
  textLineSpacing,
  buttonLink,
  buttonText,
  buttonColor,
  buttonTextColor,
  imageAnchor,
  imageFill,
  imageSpan,
  mobileImageAnchor,
  mobileImageFill,
  className,
  mobileImageSpan,
  backgroundColor,
  contentAlignment,
  // paddingBottom,
  paddingLeft,
  paddingRight,
  // paddingTop,
  productSpan,
  productFill,
  showProduct,
  showCountdown,
  showDate,
  date,
  primaryColor,
  secondaryColor,
  countdownStyle,
  countdownSpacing,
  countdownLetterSpacing,
  countdownSize,
  nft,
}) => {
  const { t } = useTranslation('home-page')

  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
  const verticalAlignment = contentAlignment.split(' ')[0]
  const horizontalAlignment = contentAlignment.split(' ')[1]
  const blockHeight =
    !showProduct && !showCountdown
      ? 'h-96'
      : (showProduct || showCountdown) && horizontalAlignment === 'center'
      ? 'h-[900px]'
      : 'h-[475px]'
  const mobileBlockHeight =
    !showProduct && !showCountdown
      ? 'h-96'
      : (showProduct || showCountdown) && horizontalAlignment
      ? `min-h-[500px] max-h-[1200px]`
      : 'h-[475px]'

  const renderProductSpotlight = (): JSX.Element => (
    <div
      className={classNames(isMobile ? 'w-full' : undefined, 'mx-auto', 'flex', 'justify-center')}
    >
      <div>
        <ProductSpotlight
          inHero
          nftId={nft?.id}
          image={productSpan !== '' ? productSpan : nft?.thumbnailList?.big ?? nft?.file}
          productSize={productFill}
          contractAddress={nft?.contractAddress}
          title={nft?.name}
          currency={nft?.currency}
          number={nft?.remainingCopies}
          price={
            nft.saleType === 'auction'
              ? nft.avgBidPrice
              : nft.storefrontUrl.includes('bv')
              ? nft.costPerItem
              : nft.ethPrice
          }
          isFavorite={nft?.isFavorite}
          blockchainCurrency={
            nft?.storefrontBlockchainInfo?.blockchainContract.blockchain.currency ?? 'ETH'
          }
          blockchainType={
            nft?.storefrontBlockchainInfo?.blockchainContract.blockchain.type ?? 'ethereum'
          }
          isAirdrop={nft?.saleType === 'airdrop'}
          hasEdition={nft?.hasEdition}
          blockchainCurrencyIcon={
            nft?.storefrontBlockchainInfo?.blockchainContract.blockchain.currencyIcon
          }
          storefrontUrl={nft?.storefrontUrl}
        />
      </div>
    </div>
  )

  return (
    <div
      className={classNames(
        className,
        'w-full',
        'relative',
        isMobile ? mobileBlockHeight : blockHeight
      )}
    >
      <div
        style={{
          backgroundColor: backgroundColor || 'transparent',
        }}
        className={isMobile ? mobileBlockHeight : blockHeight}
      >
        <div
          className={classNames(
            'flex',
            'items-center',
            'h-full',
            'bg-no-repeat',
            'lg:px-32',
            'container',

            {
              'bg-auto': mobileImageSpan
                ? mobileImageFill === 'original'
                : imageFill === 'original',
            },
            { 'bg-cover': mobileImageSpan ? mobileImageFill === 'zoom' : imageFill === 'zoom' },
            { 'bg-contain': mobileImageSpan ? mobileImageFill === 'fit' : imageFill === 'fit' },
            { 'bg-left': mobileImageSpan ? mobileImageAnchor === 'left' : imageAnchor === 'left' },
            {
              'bg-center': mobileImageSpan
                ? mobileImageAnchor === 'center'
                : imageAnchor === 'center',
            },
            {
              'bg-right': mobileImageSpan ? mobileImageAnchor === 'right' : imageAnchor === 'right',
            },

            { 'sm:bg-auto': imageFill === 'original' },
            { 'sm:bg-cover': imageFill === 'zoom' },
            { 'sm:bg-contain': imageFill === 'fit' },
            { 'sm:bg-left': imageAnchor === 'left' },
            { 'sm:bg-center': imageAnchor === 'center' },
            { 'sm:bg-right': imageAnchor === 'right' }
          )}
          style={{
            backgroundImage:
              isMobile && mobileImageSpan ? `url(${mobileImageSpan})` : `url(${imageSpan})`,
          }}
        >
          <div
            className={`flex flex-col w-full h-full`}
            style={{ justifyContent: verticalAlignment }}
          >
            <div
              className={classNames(
                {
                  'flex-row justify-between':
                    showProduct && horizontalAlignment != 'center' && !isMobile,
                },
                {
                  'flex-row': !showProduct && !isMobile,
                },
                { 'flex-col': isMobile },
                { 'flex-col': showProduct && horizontalAlignment === 'center' },
                'w-full flex gap-10',
                !showProduct ? `justify-${horizontalAlignment}` : null,
                `pr-${Number(paddingRight) * 2}`,
                `pl-${Number(paddingLeft) * 2}`
              )}
            >
              {horizontalAlignment === 'end' && showProduct && renderProductSpotlight()}

              <div
                className={classNames(
                  isMobile
                    ? 'w-full'
                    : !showProduct
                    ? 'w-3/5'
                    : horizontalAlignment === 'center'
                    ? 'w-[475px]'
                    : 'w-3/5',
                  {
                    'mx-auto': showProduct && textAlign === 'center',
                  },
                  {
                    'self-start': verticalAlignment === 'start',
                    'self-center': verticalAlignment === 'center',
                    'self-end': verticalAlignment === 'end',
                  }
                )}
              >
                <TextElementBlock
                  mainText={mainText}
                  subText={subText}
                  fontColor={textColor}
                  textSize={textSize}
                  textAlign={textAlign}
                  textBold={textBold}
                  textUnderline={textUnderline}
                  textStrikeThrough={textStrikeThrough}
                  textLetterSpacing={textLetterSpacing}
                  textLineSpacing={textLineSpacing}
                  inHero
                  className="pb-4"
                />

                {showCountdown ? (
                  <div
                    className={classNames('mb-4 w-fit', {
                      'mx-auto': textAlign === 'center',
                      'ml-auto': textAlign === 'right',
                    })}
                  >
                    <CountdownBlock
                      date={date}
                      primaryColor={primaryColor}
                      secondaryColor={secondaryColor}
                      countdownStyle={countdownStyle}
                      countdownSize={countdownSize}
                      countdownSpacing={countdownSpacing}
                      countdownLetterSpacing={countdownLetterSpacing} // padding here
                    />
                  </div>
                ) : null}

                <div
                  className={classNames(
                    'flex',
                    isMobile ? 'flex-col' : 'flex-row',
                    'items-center',
                    {
                      'justify-center': textAlign === 'center',
                      'justify-end': textAlign === 'right',
                    },
                    'gap-4'
                  )}
                >
                  {buttonText ? (
                    <div>
                      <ButtonBlock
                        buttonText={buttonText}
                        buttonLink={buttonLink}
                        box
                        buttonTextColor={buttonTextColor}
                        buttonColor={buttonColor}
                      />
                    </div>
                  ) : null}
                  {showCountdown && showDate ? (
                    <div
                      className={classNames(
                        'flex',
                        'flex-grow-0',
                        'flex-nowrap',
                        'font-bold',
                        'whitespace-nowrap'
                      )}
                      style={{ color: textColor as string }}
                    >
                      {t('availableDate', { date })}
                    </div>
                  ) : null}
                </div>
              </div>

              {(horizontalAlignment == 'start' || horizontalAlignment == 'center') &&
                showProduct &&
                renderProductSpotlight()}
            </div>
          </div>
        </div>
      </div>
      <div className="clear-both" />
    </div>
  )
}
export default HeroBlock
