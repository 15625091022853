import { APINFT } from '@betablocks/shared/lib/api/nfts'
import { PageLayoutBlockCarousel } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useRef, useState } from 'react'
import BaseCarousel, { ReactElasticCarouselProps } from 'react-elastic-carousel'
import { useMediaQuery } from 'react-responsive'
import LeftCarouselArrow from '../../../../assets/icons/LeftCarouselArrow'
import RightCarouselArrow from '../../../../assets/icons/RightCarouselArrow'
import Button from '../../../Atoms/NewButton'
import ProductSpotlight from '../../ProductSpotlight'

// 03/05/2022 - Hack to fix the issue with carousel props not having an explicit `children` prop.
const Carousel = BaseCarousel as unknown as React.FC<
  React.PropsWithChildren<ReactElasticCarouselProps>
>

type Props = {
  title: PageLayoutBlockCarousel['data']['title']
  products: APINFT[]
  collectionId?: PageLayoutBlockCarousel['data']['category']
  tags?: PageLayoutBlockCarousel['data']['tags']
  span: number
}

const ProductCarouselBlock: React.FC<Props> = ({ title, products, collectionId, tags, span }) => {
  const { t } = useTranslation('home-page')
  const blockHeight = `h-auto`
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  const seeAllLink = {
    pathname: '/browse',
    query: {
      collection__in: collectionId as number,
      tags__name__in: (tags as string[])?.filter(Boolean)?.join(','),
    },
  }

  const ref = useRef(null)
  const [dimension, setDimension] = useState({ width: 0 })

  useEffect(() => {
    if (ref.current) {
      setDimension({ width: ref.current.offsetWidth })
    }
  }, [dimension.width])

  const breakPoints = [
    { width: 1, itemsToShow: 2, itemsToScroll: 2, itemPadding: [24, 8] },
    { width: 640, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4, itemsToScroll: 4 },
  ]

  const myArrow = ({ type, onClick, isEdge }): React.ReactElement => {
    //use string 'PREV' instead of docs-recommended consts.PREV due to TS error on import:
    //import {consts} from 'react-elastic-carousel'
    const pointer = type === 'PREV' ? <LeftCarouselArrow /> : <RightCarouselArrow />

    return (
      <div className="flex items-center w-12">
        <button
          className={classNames(
            'w-12',
            'h-12',
            'active:scale-1',
            'active:scale-90',
            'active:transform',
            'disabled:opacity-50',
            'disabled:ring-0',
            'hover:ring-2',
            'hover:ring-bg-6',
            'hover:ring-opacity-50',
            'rounded-full',
            'transition-all'
          )}
          onClick={onClick}
          disabled={isEdge}
        >
          {pointer}
        </button>
      </div>
    )
  }

  return (
    <div className={classNames('items-stretch', 'w-full')}>
      <div className="flex flex-col md:flex-row justify-between md:items-center gap-4 mb-4">
        <div
          className="font-black text-bg-8 text-3xl sm:m-0 text-ellipsis whitespace-nowrap overflow-hidden"
          title={title as string}
        >
          {title as string}
        </div>
        <Button href={seeAllLink} box variant="default" className={`text-sm`}>
          {t('button.seeAll')}
        </Button>
      </div>

      <div className={classNames('gap-4', '-mx-4 lg:-mx-8', blockHeight)} ref={ref}>
        <Carousel
          isRTL={false}
          breakPoints={breakPoints}
          renderArrow={myArrow}
          itemPadding={[24, 22]}
          pagination
          showArrows={false}
          // itemsToShow={span < 9 ? 2 : span < 12 ? 3 : 4} // stick to part when a product spotlight's span is 3
          outerSpacing={
            isMobile && products.length == 1
              ? (dimension.width - 20) / 4
              : !isMobile && products.length == 3 && span > 9
              ? ((dimension.width - 20) * (1 - 9 / span)) / 2
              : !isMobile && products.length == 2 && span > 6
              ? ((dimension.width - 20) * (1 - 6 / span)) / 2
              : !isMobile && products.length == 1 && span > 5
              ? ((dimension.width - 20) * (1 - 3 / span)) / 2
              : 0
          }
        >
          {products.map((nft, idx) => (
            <ProductSpotlight
              key={idx}
              title={nft?.name}
              currency={nft?.currency}
              number={nft?.remainingCopies}
              price={
                nft.saleType === 'auction'
                  ? nft.avgBidPrice
                  : nft.storefrontUrl.includes('bv')
                  ? nft.costPerItem
                  : nft.ethPrice
              }
              contractAddress={nft?.contractAddress}
              nftId={nft?.id}
              image={nft?.thumbnailList?.big ?? nft?.file}
              isFavorite={nft?.isFavorite}
              blockchainCurrency={
                nft ? nft.storefrontBlockchainInfo?.blockchainContract.blockchain.currency : 'ETH'
              }
              blockchainType={
                nft ? nft.storefrontBlockchainInfo.blockchainContract.blockchain.type : 'ethereum'
              }
              tooltipId={`carousel-${nft.id}`}
              isAirdrop={nft?.saleType === 'airdrop'}
              hasEdition={nft?.hasEdition}
              blockchainCurrencyIcon={
                nft.storefrontBlockchainInfo?.blockchainContract.blockchain.currencyIcon
              }
              storefrontUrl={nft?.storefrontUrl}
            />
          ))}
        </Carousel>
      </div>
    </div>
  )
}
export default ProductCarouselBlock
