import { PageLayoutBlockText } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import striptags from 'striptags'
import Text from '../../../Atoms/Text'

type Props = {
  as?: PageLayoutBlockText['data']['as']
  mainText: PageLayoutBlockText['data']['title']
  subText?: PageLayoutBlockText['data']['body']
  className?: string
  fontColor?: PageLayoutBlockText['data']['color']
  textSize?: PageLayoutBlockText['data']['size']
  textAlign?: PageLayoutBlockText['data']['align']
  textBold?: PageLayoutBlockText['data']['bold']
  textUnderline?: PageLayoutBlockText['data']['underline']
  textStrikeThrough?: PageLayoutBlockText['data']['strikeThrough']
  textLetterSpacing?: PageLayoutBlockText['data']['letterSpacing']
  textLineSpacing?: PageLayoutBlockText['data']['spacing']
  inHero?: boolean
}

const TextElementBlock: React.FC<Props> = ({
  as = 'h1',
  mainText,
  subText,
  className,
  fontColor,
  textSize = 10,
  textAlign = 'center',
  textBold,
  textUnderline,
  textStrikeThrough,
  textLetterSpacing,
  textLineSpacing,
  inHero = false,
}) => {
  const hasMainText = striptags(mainText).trim().length > 0
  const hasSubText = striptags(subText).trim().length > 0

  return (
    <div className={classNames(className)}>
      {hasMainText ? (
        <Text
          as={as}
          color={fontColor as string}
          className={classNames(
            'break-words',
            'whitespace-pre-line',
            inHero || !hasSubText ? 'mb-0' : 'mb-8',
            'text-center',
            {
              'md:text-center': textAlign === 'center',
              'md:text-left': textAlign === 'left',
              'md:text-right': textAlign === 'right',
              'md:text-justify': textAlign === 'justify',
              'font-black': textBold,
              underline: textUnderline && !textStrikeThrough,
              'line-through': textStrikeThrough && !textUnderline,
              'underline-line-through': textUnderline && textStrikeThrough,
            }
          )}
          style={{
            letterSpacing: textLetterSpacing ?? '100%',
            lineHeight: textLineSpacing,
            fontSize: `${(textSize ?? 12) + 10}px`,
          }}
          noMargin
        >
          {mainText as string}
        </Text>
      ) : null}

      {hasSubText ? (
        <div
          dangerouslySetInnerHTML={{ __html: subText as string }}
          className={classNames(
            'ql-editor',
            'break-words',
            'my-4',
            'p-0',
            inHero ? 'text-lg' : 'text-md',
            'whitespace-pre-line'
          )}
        />
      ) : null}
    </div>
  )
}

export default TextElementBlock
