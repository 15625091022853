const LeftCarouselArrow: React.FC = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="48" height="48" rx="25" fill="#E7E7E7" />
    <path
      d="M29.6312 19.7625L27.8687 18L20.3687 25.5L27.8687 33L29.6312 31.2375L23.9062 25.5L29.6312 19.7625Z"
      fill="#4A5056"
    />
  </svg>
)
export default LeftCarouselArrow
