import { PageLayoutBlockText } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import TextElementBlock from '../../Blocks/TextElementBlock'

type Props = {
  data: PageLayoutBlockText['data']
}

const RenderTextElementBlock: React.FC<Props> = ({ data }) => {
  const {
    as,
    title,
    body,
    color,
    size,
    align,
    bold,
    underline,
    strikeThrough,
    letterSpacing,
    spacing,
  } = data

  return (
    <div className={classNames('flex', 'flex-col', 'w-full')}>
      <TextElementBlock
        as={as}
        mainText={title}
        subText={body}
        fontColor={color}
        textSize={size}
        textAlign={align}
        textBold={bold}
        textUnderline={underline}
        textStrikeThrough={strikeThrough}
        textLetterSpacing={letterSpacing}
        textLineSpacing={spacing}
      />
    </div>
  )
}

export default RenderTextElementBlock
