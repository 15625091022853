import { APINFT } from '@betablocks/shared/lib/api/nfts'
import { PageLayoutBlockHero } from '@betablocks/shared/lib/api/pages'
import HeroBlock from '../../Blocks/HeroBlock'

type Props = {
  data: PageLayoutBlockHero['data']
  nfts?: APINFT[]
}

const RenderHeroBlock: React.FC<Props> = ({ data, nfts }) => {
  const { text, button, banner } = data
  const { image, product, countdown } = banner['data']

  const {
    title: mainText = '',
    body: subText = '',
    color: textColor = '',
    align,
    size,
    bold,
    underline,
    strikeThrough,
    letterSpacing,
    spacing,
  } = text['data']
  const {
    href: buttonLink = '',
    text: buttonText = '',
    backgroundColor: buttonBackgroundColor = '',
    color: buttonTextColor = '',
  } = button['data']
  const {
    src: imageSpan = '',
    fill: imageFill = 'original',
    anchor: imageAnchor = 'center',
    backgroundColor: backgroundColor = '',
    mobileSrc: mobileImageSpan = '',
    mobileFill: imageMobileFill = 'original',
    mobileAnchor: imageMobileAnchor = 'center',
    contentAlignment = 'center start',
    paddingTop = '4',
    paddingBottom = '4',
    paddingLeft = '4',
    paddingRight = '4',
  } = image['data']
  const {
    showProduct,
    imageOverrideSrc: productSpan = '',
    fill: productFill = 'original',
    anchor: productAnchor = 'center',
    productId: pid,
  } = product['data']
  const {
    showCountdown,
    showDate,
    date: date = '',
    primaryColor: primaryColor = '',
    secondaryColor: secondaryColor = '',
    style: countdownStyle = 'h1',
    spacing: countdownSpacing,
    letterSpacing: countdownLetterSpacing,
    size: countdownSize,
  } = countdown['data']

  const nft = nfts?.find((nft) => nft.id === pid)

  return (
    <HeroBlock
      mainText={mainText}
      subText={subText}
      buttonText={buttonText}
      buttonLink={buttonLink}
      imageSpan={imageSpan}
      mobileImageSpan={mobileImageSpan}
      imageFill={imageFill}
      imageAnchor={imageAnchor}
      backgroundColor={backgroundColor}
      textColor={textColor}
      textAlign={align}
      textBold={bold}
      textUnderline={underline}
      textStrikeThrough={strikeThrough}
      textLetterSpacing={letterSpacing}
      textLineSpacing={spacing}
      textSize={size}
      buttonTextColor={buttonTextColor}
      buttonColor={buttonBackgroundColor}
      mobileImageFill={imageMobileFill}
      mobileImageAnchor={imageMobileAnchor}
      contentAlignment={contentAlignment}
      paddingTop={paddingTop}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      productSpan={productSpan}
      productFill={productFill}
      productAnchor={productAnchor}
      showProduct={showProduct}
      nft={nft}
      showCountdown={showCountdown}
      showDate={showDate}
      date={date}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      countdownStyle={countdownStyle}
      countdownSpacing={countdownSpacing}
      countdownLetterSpacing={countdownLetterSpacing}
      countdownSize={countdownSize}
    />
  )
}

export default RenderHeroBlock
