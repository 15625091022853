import { APICollectionListWithNFT } from '@betablocks/shared/lib/api/collections'
import { APINFT, APITagListWithNFT } from '@betablocks/shared/lib/api/nfts'
import { APIPage } from '@betablocks/shared/lib/api/pages'
import Head from 'next/head'
import useStorefront from '../../../hooks/useStorefront'
import RenderBlocks from '../../Widgets/Blocks/RenderBlocks'
import PagesLayout from '../../Widgets/PagesLayout'
import PelxpHome from '../PelxpHome'

type Props = {
  page: APIPage
  nftsByCollection: APICollectionListWithNFT
  nftsByTag: APITagListWithNFT
  nfts: APINFT[]
  showLoginMenu?: boolean
}

const StorePage: React.FC<Props> = ({
  page,
  nftsByCollection,
  nftsByTag,
  nfts,
  showLoginMenu = false,
}) => {
  const storefront = useStorefront()
  const isPelxp = storefront.id === +process.env.NEXT_PUBLIC_PELXP_STOREFRONT_ID
  const { data } = page

  if (isPelxp) {
    return <PelxpHome showLoginMenu={showLoginMenu} page={page} />
  }

  return (
    <PagesLayout
      showLoginMenu={showLoginMenu}
      noVerticalPadding
      noHorizontalPadding
      restrict={false}
      showAirdropPopup
    >
      <Head>
        <title>{page.title}</title>
      </Head>
      <RenderBlocks
        data={data}
        nftsByCollection={nftsByCollection}
        nftsByTag={nftsByTag}
        nfts={nfts}
      />
    </PagesLayout>
  )
}
export default StorePage
