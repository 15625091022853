const RightCarouselArrow: React.FC = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="48" height="48" rx="25" fill="#E7E7E7" />
    <path
      d="M22.1312 18L20.3687 19.7625L26.0937 25.5L20.3687 31.2375L22.1312 33L29.6312 25.5L22.1312 18Z"
      fill="#4A5056"
    />
  </svg>
)
export default RightCarouselArrow
