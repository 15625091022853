import { PageLayoutRef } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LikeIcon from '../../../assets/icons/Like'
import DislikeIcon from '../../../assets/icons/LikeOutline'
import Blank from '../../../assets/images/blank.svg'
import useApi from '../../../hooks/useApi'
import useMultichain from '../../../hooks/useMultichain'
import useStorefront from '../../../hooks/useStorefront'
import useUser from '../../../hooks/useUser'
import BmwCurrency from '../../Atoms/BmwCurrency'
import Currency from '../../Atoms/Currency'
import Text from '../../Atoms/Text'
import Tooltip from '../../Atoms/Tooltip'
import BlockchainCurrency from '../BlockchainCurrency'

type Props = {
  title?: string
  number?: number
  currency?: string
  price?: number | string
  image?: string | PageLayoutRef
  imageFill?: string
  contractAddress?: string
  nftId?: number
  editionTokenId?: string
  editionSequenceNumber?: string
  isFavorite: boolean
  blockchainCurrency: string
  blockchainType: 'ethereum' | 'stellar'
  tooltipId?: string
  inHero?: boolean
  productSize?: 'fit' | 'zoom' | 'original'
  isAirdrop: boolean
  hasEdition: boolean
  blockchainCurrencyIcon?: string
  isImported?: boolean
  storefrontUrl?: string
}

const ProductSpotlight: React.FC<Props> = ({
  number,
  title,
  price,
  currency,
  image,
  contractAddress,
  nftId,
  editionTokenId,
  editionSequenceNumber,
  isFavorite,
  blockchainCurrency,
  blockchainType,
  tooltipId,
  inHero,
  productSize,
  isAirdrop,
  hasEdition,
  blockchainCurrencyIcon,
  isImported,
  storefrontUrl,
}) => {
  const { t } = useTranslation(['home-page'])
  const router = useRouter()
  const api = useApi()

  const storefront = useStorefront()

  const isBMW = !!storefrontUrl?.toLowerCase()?.includes('bmw')
  const isDonation = storefront?.isDonation
  const isPaidByOwner = storefront?.isPaidByOwner
  const { backend } = useMultichain(blockchainType)
  const me = useUser()
  const [like, setLike] = useState(isFavorite)

  const handleLikeToggle = async (e: React.SyntheticEvent): Promise<void> => {
    e.stopPropagation()
    e.preventDefault()

    if (like) {
      try {
        await api.nfts.delFavorite(nftId)
      } catch (e) {
        return
      }
    } else {
      try {
        await api.nfts.setFavorite(nftId)
      } catch (e) {
        return
      }
    }

    setLike((prev) => !prev)
  }

  // Build link to edition.
  let href = '.'
  if (nftId) {
    href = `/product/${contractAddress || 0}/${nftId}`
    if (editionTokenId) {
      href = `${href}/${editionTokenId}`
    }
    if (isImported) {
      href = `${href}/?imported=1`
    }
  }

  return (
    <Link href={href} passHref>
      <a
        className={classNames(
          'flex',
          'flex-col',
          'hover:ring-2',
          'hover:ring-theme-primary',
          'transition-all',
          'rounded-md',
          'shadow-md',
          'hover:shadow-theme-primary',
          { 'h-[350px] min-w-[18rem] max-w-[475px]': inHero }
        )}
      >
        <div
          className={classNames(
            { 'rounded-md bg-black': inHero },
            { 'rounded-t-md relative bg-theme-backgroundLight': !inHero }
          )}
        >
          <Image
            src={image || Blank}
            unoptimized
            alt=""
            width={inHero ? 384 : 288}
            height={inHero ? 350 : 225}
            layout="raw"
            className={classNames(
              { 'h-[350px] rounded-md': inHero },
              { 'md:h-72 rounded-t-md bg-theme-background': !inHero },
              productSize === 'fit'
                ? 'object-contain'
                : productSize === 'original'
                ? 'object-none'
                : 'object-contain'
            )}
            style={{
              backgroundColor: productSize ? 'transparent' : undefined,
            }}
          />

          {me && nftId && !inHero && !isImported ? (
            <div
              className="absolute z-10 top-2 left-2 rounded-full flex flex-col justify-center bg-theme-primaryLight p-1 hover:ring-2 hover:ring-theme-primary transition-all text-theme-primary"
              onClick={handleLikeToggle}
              onKeyPress={handleLikeToggle}
              tabIndex={0}
              role="button"
            >
              {like ? <LikeIcon width={18} height={18} /> : <DislikeIcon width={18} height={18} />}
            </div>
          ) : null}
        </div>
        {inHero ? null : (
          <div className="flex-1 flex flex-col md:flex-row md:justify-between md:items-center bg-theme-background px-3 py-1 gap-1 rounded-b-md">
            <div className="flex flex-col truncate">
              <Text
                noMargin
                as="p"
                color="text-bg-10"
                className="text-md font-medium text-left w-full truncate"
              >
                {title ?? '???'}
              </Text>

              {!isDonation && !isPaidByOwner && (
                <Text noMargin as="p" color="text-bg-7" className="text-xs font-bold capitalize">
                  {isImported
                    ? t('card.imported', {
                        tokenId: backend.getTokenIdDisplay(editionSequenceNumber),
                      })
                    : editionSequenceNumber
                    ? backend.getTokenIdDisplay(editionSequenceNumber)
                    : number
                    ? t('card.remainingCopies', { number })
                    : t('card.soldOut')}
                </Text>
              )}
            </div>
            <div className="flex flex-col md:items-end">
              <Text noMargin as="p" color="text-bg-7" className="text-xs">
                {t('card.priceTitle')}
              </Text>
              <div className="flex items-center gap-1 text-theme-primary">
                {currency && (
                  <>
                    {isDonation ? (
                      <Currency currency="BRL" tooltipId={tooltipId} />
                    ) : isBMW ? (
                      <BmwCurrency tooltipId={tooltipId} />
                    ) : (
                      <Tooltip
                        id={`tooltip-${tooltipId}`}
                        hoverText={blockchainCurrency}
                        icon={
                          <BlockchainCurrency
                            currency={blockchainCurrency}
                            currencyIcon={blockchainCurrencyIcon}
                            height={14}
                            width={14}
                          />
                        }
                      />
                    )}
                  </>
                )}

                {/* Disable other currency display for now 9/12/2021 */}
                {/* {currency?.toUpperCase() === 'ETH' ? (
                <EthIcon width={14} height={14} />
              ) : null}

              {currency?.toUpperCase() === 'USD' ? (
                <Text as="p" className="text-sm font-black" noMargin>
                  $
                </Text>
              ) : null} */}

                <Text
                  noMargin
                  as="p"
                  color="text-bg-7"
                  className="font-bold text-xs text-theme-primary capitalize"
                >
                  {isImported
                    ? '-'
                    : !isAirdrop
                    ? new Intl.NumberFormat(router.locale, {
                        maximumFractionDigits: 6,
                      }).format(price as number)
                    : hasEdition
                    ? t('card.claimed')
                    : t('card.free')}
                </Text>
              </div>
            </div>
          </div>
        )}
      </a>
    </Link>
  )
}
export default ProductSpotlight
