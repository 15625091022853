import { PageLayoutBlockVideo } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import React from 'react'
import ReactPlayer from 'react-player'

type Props = {
  data?: PageLayoutBlockVideo['data']
}

const VideoElement: React.FC<Props> = ({ data }) => {
  return (
    <div
      className={classNames(
        'rounded-t-md',
        'bg-theme-backgroundLight',
        'relative',
        'leading-0',
        'aspect-video'
      )}
    >
      <ReactPlayer url={data.url} width="100%" height="100%" />
    </div>
  )
}
export default VideoElement
