import { PageLayoutBlockImage } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import React from 'react'

type Props = {
  data?: PageLayoutBlockImage['data']
}

/* eslint-disable @next/next/no-img-element */
const ImageElement: React.FC<Props> = ({ data }) => {
  return (
    <div
      className={classNames('flex')}
      style={{ backgroundColor: data.backgroundColor || 'transparent' }}
    >
      <img src={data.src as string} alt="" className="flex-1" />
    </div>
  )
}
export default ImageElement
