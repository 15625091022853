import { PageLayoutBlockCountdown } from '@betablocks/shared/lib/api/pages'
import { useTranslation } from 'next-i18next'
import Countdown, { zeroPad } from 'react-countdown'
import Text from '../../../Atoms/Text'

import React, { ReactNode } from 'react'

type Props = {
  date?: PageLayoutBlockCountdown['data']['date']
  primaryColor: PageLayoutBlockCountdown['data']['primaryColor']
  secondaryColor: PageLayoutBlockCountdown['data']['secondaryColor']
  countdownStyle: PageLayoutBlockCountdown['data']['style']
  countdownSize: PageLayoutBlockCountdown['data']['size']
  countdownSpacing: PageLayoutBlockCountdown['data']['spacing']
  countdownLetterSpacing: PageLayoutBlockCountdown['data']['letterSpacing']
}

const CountdownBlock: React.FC<Props> = ({
  date,
  primaryColor,
  secondaryColor,
  countdownStyle,
  countdownSize,
  countdownSpacing,
  countdownLetterSpacing,
}) => {
  const { t } = useTranslation('home-page')

  const renderCountdown = ({ days, hours, minutes, seconds }): ReactNode => {
    return (
      <div className="flex flex-row justify-between gap-2 md:gap-4 select-none">
        <div className="text-center">
          <Text
            as={countdownStyle}
            color={primaryColor as string}
            className="font-bold tabular-nums"
            style={{
              letterSpacing: countdownLetterSpacing,
              fontSize: countdownSize,
              lineHeight: countdownSpacing,
            }}
          >
            {zeroPad(days)}
          </Text>
          <div
            style={{
              color: secondaryColor as string,
            }}
          >
            {t('countdown.days')}
          </div>
        </div>

        <Text
          as={countdownStyle}
          color={primaryColor as string}
          className="font-bold"
          style={{
            letterSpacing: countdownLetterSpacing,
            fontSize: countdownSize,
            lineHeight: countdownSpacing,
          }}
        >
          :
        </Text>

        <div className="text-center">
          <Text
            as={countdownStyle}
            color={primaryColor as string}
            className="font-bold tabular-nums"
            style={{
              letterSpacing: countdownLetterSpacing,
              fontSize: countdownSize,
              lineHeight: countdownSpacing,
            }}
          >
            {zeroPad(hours)}
          </Text>
          <div
            style={{
              color: secondaryColor as string,
            }}
          >
            {t('countdown.hours')}
          </div>
        </div>

        <Text
          as={countdownStyle}
          color={primaryColor as string}
          className="font-bold"
          style={{
            letterSpacing: countdownLetterSpacing,
            fontSize: countdownSize,
            lineHeight: countdownSpacing,
          }}
        >
          :
        </Text>

        <div className="text-center">
          <Text
            as={countdownStyle}
            color={primaryColor as string}
            className="font-bold tabular-nums"
            style={{
              letterSpacing: countdownLetterSpacing,
              fontSize: countdownSize,
              lineHeight: countdownSpacing,
            }}
          >
            {zeroPad(minutes)}
          </Text>
          <div
            style={{
              color: secondaryColor as string,
            }}
          >
            {t('countdown.minutes')}
          </div>
        </div>

        <Text
          as={countdownStyle}
          color={primaryColor as string}
          className="font-bold"
          style={{
            letterSpacing: countdownLetterSpacing,
            fontSize: countdownSize,
            lineHeight: countdownSpacing,
          }}
        >
          :
        </Text>

        <div className="text-center">
          <Text
            as={countdownStyle}
            color={primaryColor as string}
            className="font-bold tabular-nums"
            style={{
              letterSpacing: countdownLetterSpacing,
              fontSize: countdownSize,
              lineHeight: countdownSpacing,
            }}
          >
            {zeroPad(seconds)}
          </Text>
          <div
            style={{
              color: secondaryColor as string,
            }}
          >
            {t('countdown.seconds')}
          </div>
        </div>
      </div>
    )
  }

  return <Countdown date={new Date(date)} renderer={renderCountdown} zeroPadTime={2} />
}

export default CountdownBlock
