import { APINFT } from '@betablocks/shared/lib/api/nfts'
import { PageLayoutBlockProduct } from '@betablocks/shared/lib/api/pages'

import ProductSpotlight from '../../ProductSpotlight'

type Props = {
  data: PageLayoutBlockProduct['data']
  nfts: APINFT[]
}

const RenderProductSpotlightBlock: React.FC<Props> = ({ data, nfts }) => {
  const { productId: pid } = data
  const nft = nfts.find((nft) => nft.id === pid)

  if (!nft) {
    return null
  }

  return (
    <ProductSpotlight
      title={nft?.name}
      currency={nft?.currency}
      number={nft?.remainingCopies}
      price={
        nft.saleType === 'auction'
          ? nft.avgBidPrice
          : nft.storefrontUrl.includes('bv')
          ? nft.costPerItem
          : nft.ethPrice
      }
      contractAddress={nft?.contractAddress}
      nftId={nft?.id}
      image={nft?.thumbnailList?.big ?? nft?.file}
      isFavorite={nft?.isFavorite}
      blockchainCurrency={
        nft ? nft.storefrontBlockchainInfo.blockchainContract.blockchain.currency : 'ETH'
      }
      blockchainType={
        nft ? nft.storefrontBlockchainInfo.blockchainContract.blockchain.type : 'ethereum'
      }
      tooltipId={`renderProduct-${nft.id}`}
      isAirdrop={nft?.saleType === 'airdrop'}
      hasEdition={nft?.hasEdition}
      blockchainCurrencyIcon={
        nft.storefrontBlockchainInfo.blockchainContract.blockchain.currencyIcon
      }
      storefrontUrl={nft?.storefrontUrl}
    />
  )
}

export default RenderProductSpotlightBlock
