/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { APINFT } from '@betablocks/shared/lib/api/nfts'
import { APIPage } from '@betablocks/shared/lib/api/pages'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect } from 'react'
import useApi from '../../../hooks/useApi'
import Button from '../../Atoms/NewButton'
import PagesLayout from '../../Widgets/PagesLayout'
import PurchaseProgress from '../../Widgets/PurchaseProgress'

type Props = {
  showLoginMenu?: boolean
  page: APIPage
}

const milestonesData = [
  {
    amount: 100,
    label: '100k',
    image: '/images/pelxp_reward_one.png',
    description: "Champion's Halo: 2009 Lakers Ring",
  },
  {
    amount: 200,
    label: '200k',
    image: '/images/pelxp_reward_two.png',
    description: "Step into Greatness: Kobe's Signed Sneakers",
  },
  {
    amount: 400,
    label: '400k',
    image: '/images/pelxp_reward_three.png',
    description: "Jump Higher: Jordan's Autographed Shoes",
  },
]

const PelxpHome: React.FC<Props> = ({ showLoginMenu, page }) => {
  return (
    <PagesLayout
      showLoginMenu={showLoginMenu}
      noVerticalPadding
      noHorizontalPadding
      restrict={false}
      showAirdropPopup
    >
      <Head>
        <title>{page.title}</title>
      </Head>
      <Banner />
      <HowWorks />
      <PurchaseProgress
        title="Your Digital Token, Your Gateway to Glory"
        subtitle="Exclusive Rewards Await with Autographed Sneakers by Jordan, Kobe, and the 2009 Lakers Championship Ring!"
        milestones={milestonesData}
      />
      <PickConference />
      <CallToAction />
      <About />
      {/* <Footer /> */}
    </PagesLayout>
  )
}

export default PelxpHome

function Banner() {
  return (
    <section
      className="flex flex-col md:h-[427px] bg-white md:max-w-[1440px] mx-auto md:px-28 py-10 md:gap-20 gap-5  "
      style={{ backgroundImage: "url('/images/pelxp_banner.png')", backgroundRepeat: 'no-repeat' }}
    >
      <div className="flex flex-col gap-1 w-full text-white text-center md:text-start md:max-w-[516px] max-w-[300px]  mx-auto md:mx-0">
        <h1 className="md:text-[50px] text-[30px]  leading-tight">
          Simply Fun: <br /> Win Real NBA Prizes!
        </h1>
        <p className="md:text-[20px]">
          Own a Piece of Basketball History with Our Exclusive Michael Jordan and Kobe Bryant NFTs!
        </p>
      </div>
      <div className="flex item-center gap-10 mx-auto md:mx-0">
        <img src="/images/pelxp_logo.svg" className="w-[90px] object-contain" alt="pelxp logo" />
        <img src="/images/power_by_betablocks_logo.svg" alt="pelxp logo" />
      </div>
    </section>
  )
}

function HowWorks() {
  return (
    <section className="flex flex-col max-w-[1440px] mx-auto gap-10 py-20">
      <div className="flex flex-col items-center gap-5">
        <h3 className="text-[50px] leading-tight text-center font-black">How it works</h3>
        <p className="text-[20px] text-center">
          Purchase NFTs, Compete, and Claim Unique Real-world Rewards!
        </p>
      </div>

      <div className="flex items-center justify-center gap-5 flex-wrap mx-auto">
        <div className="flex flex-col items-center gap-3">
          <img src="/images/1.svg" alt="one" />
          <div className="flex flex-col items-center text-center bg-black py-5  rounded-xl w-[300px]">
            <p className="bg-white px-5 py-2 rounded-xl uppercase">Buy</p>
            <p className="text-white font-bold text-[25px] leading-tight">
              Buy an ticket, <br /> <span className="font-normal">Claim your NFT</span>
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center  gap-3">
          <img src="/images/2.svg" alt="two" />
          <div className="flex flex-col items-center text-center bg-black py-5  rounded-xl w-[300px]">
            <p className="bg-white px-5 py-2 rounded-xl uppercase">Play</p>
            <p className="text-white font-bold text-[25px] leading-tight">
              Select your conference <br /> <span className="font-normal">and make your guess</span>
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-3">
          <img src="/images/3.svg" alt="three" />
          <div className="flex flex-col items-center text-center bg-black py-5  rounded-xl w-[300px]">
            <p className="bg-white px-5 py-2 rounded-xl uppercase">Buy</p>
            <p className="text-white font-bold text-[25px] leading-tight">
              Buy an ticket, <br /> <span className="font-normal">Claim your NFT</span>
            </p>
          </div>
        </div>
      </div>
      <Button className="text-black border-2 font-bold border-black px-10 py-5 rounded-xl text-lg h-[66px] w-[175px] mx-auto">
        Join now
      </Button>
    </section>
  )
}

function CallToAction() {
  return (
    <section className="flex md:flex-row flex-col items-center justify-center max-w-[1440px] mx-auto gap-10 md:py-40 my-10 ">
      <img src="/images/pelxp_image_one.png" alt="pelxp prizes images" className="" />
      <div className="flex flex-col items-center text-center md:text-start md:items-start  gap-5 ">
        <h3 className="md:text-[60px] text-4xl leading-tight font-bold">
          Exclusive Game <br /> Prizes Revealed
        </h3>
        <p className="md:text-[30px] text-xl leading-tight">
          See the Coveted Lakers <br /> Championship Ring, Autographed <br />
          Ball, and {"Player's"} Sneaker
        </p>
        <Button className="bg-black text-white px-10 py-5 rounded-xl text-lg h-[66px] w-[175px]">
          Join now
        </Button>
      </div>
    </section>
  )
}

function About() {
  return (
    <section
      className="flex md:flex-row flex-col items-center  md:max-w-[1440px] mx-auto  justify-between md:py-40 py-10 px-5 md:px-40 gap-5
    bg-gradient-to-r from-white via-white to-[#D3BEFF] text-center md:text-start
    "
    >
      <div className="flex flex-col gap-10 mx-auto md:mx-0 order-2 md:order-1">
        <h3 className="md:text-[60px] text-4xl leading-tight font-bold">
          Meet PelXP: Your <br /> Trusted Partner in <br />
          the Crypto World
        </h3>
        <p className="text-[30px] leading-tight">
          Discover the Vision, Expertise, and <br /> Innovation Behind PelXP
        </p>
        <Button className="text-black border-2 border-black px-10 py-5 rounded-xl text-lg h-[66px] w-[175px] mx-auto md:mx-0">
          About Us
        </Button>
      </div>
      <img
        src="/images/pelxp_logo_black.svg"
        alt="pelxp logo"
        className="md:mr-14 order-1 !w-[380px] sm:w-auto "
      />
    </section>
  )
}

function PickConference() {
  const api = useApi()
  const router = useRouter()

  const [nfts, setNfts] = React.useState<{ western: APINFT; eastern: APINFT } | null>(null)

  const fetchNfts = useCallback(async () => {
    try {
      const res = await api.nfts.list()
      const nftsAvailable = res?.results?.filter((nft: APINFT) => nft.listed)
      const western = nftsAvailable?.find((nft: APINFT) => {
        return nft.name.toLowerCase().includes('western')
      })

      const eastern = nftsAvailable?.find((nft: APINFT) => {
        return nft.name.toLowerCase().includes('eastern')
      })

      if (!western || !eastern) return

      setNfts({
        western,
        eastern,
      })
    } catch (error) {
      console.log({ error })
    }
  }, [api])

  console.log(nfts)

  async function handleSelectConference(conference: 'western' | 'eastern') {
    const nft = nfts?.[conference]
    if (!nft) return
    const route = `/product/${nft.contractAddress}/${nft.id}/`
    return router.push(route)
  }

  useEffect(() => {
    if (!nfts) fetchNfts()
  }, [fetchNfts, nfts])

  if (!nfts) return null

  return (
    <section className="flex flex-col max-w-[1440px] mx-auto gap-10 py-20 bg-black text-white px-5">
      <div className="flex flex-col items-center gap-5">
        <h3 className="md:text-[50px] text-4xl leading-tight text-center font-black">
          Pick your conference{' '}
        </h3>
        <p className="md:text-[20px] text-mdtext-center">
          Purchase a Conference Ticket, Get an Exclusive Digital <br /> Collectible, and Enter for a
          Chance to Win Prizes!
        </p>
      </div>

      <div className="flex items-center justify-center gap-10 flex-wrap mx-auto">
        <div
          className="flex flex-col items-center gap-3 h-[336px] relative cursor-pointer hover:scale-95 transition-all ease-in-out "
          onClick={() => handleSelectConference('western')}
        >
          <img
            src={nfts?.western?.thumbnailList?.medium}
            alt={nfts?.western?.name}
            className=" w-[200px] h-[200px] border-white rounded-2xl z-10"
          />
          <div className="flex flex-col items-center justify-center  border-4 border-white rounded-2xl h-[180px] w-[300px] mt-[-35px]">
            <h3 className="font-bold text-[35px]">Western</h3>
            <p className="text-center text-[35px]">
              $ {parseFloat(String(nfts?.western?.costPerItem)).toString()}
            </p>
          </div>
        </div>
        <div
          className="flex flex-col items-center gap-3 h-[336px] relative cursor-pointer hover:scale-95 transition-all ease-in-out "
          onClick={() => handleSelectConference('eastern')}
        >
          <img
            src={nfts?.eastern?.thumbnailList?.medium}
            alt={nfts?.eastern?.name}
            className=" w-[200px] h-[200px] border-white rounded-2xl z-10"
          />
          <div className="flex flex-col items-center justify-center  border-4 border-white rounded-2xl h-[180px] w-[300px] mt-[-35px]">
            <h3 className="font-bold text-[35px]">Eastern</h3>
            <p className="text-center text-[35px]">
              $ {parseFloat(String(nfts?.eastern?.costPerItem)).toString()}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

// function Footer() {
//   return (
//     <footer className="flex justify-between w-full max-w-[1440px] mx-auto items-center h-[178px] bg-white p-20 ">
//       <div className="flex flex-col">
//         <Link href="/">About Us</Link>
//         <Link href="/">Terms of use</Link>
//         <Link href="/">Privacy Policy</Link>
//       </div>
//       <img
//         src="/images/pelxp_logo_black.svg"
//         alt="pelxp logo"
//         className="h-[86px] object-contain"
//       />
//     </footer>
//   )
// }
