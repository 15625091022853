import { PageLayoutBlockCountdown } from '@betablocks/shared/lib/api/pages'
import CountdownBlock from '../../Blocks/CountdownBlock'

type Props = {
  data: PageLayoutBlockCountdown['data']
}

const RenderCountdownBlock: React.FC<Props> = ({ data }) => {
  const { date, primaryColor, secondaryColor, style, size, spacing, letterSpacing } = data

  return (
    <div>
      <CountdownBlock
        date={date}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        countdownStyle={style}
        countdownSize={size}
        countdownSpacing={spacing}
        countdownLetterSpacing={letterSpacing}
      />
    </div>
  )
}

export default RenderCountdownBlock
