import { PageLayoutBlockButton } from '@betablocks/shared/lib/api/pages'
import classNames from 'classnames'
import Button from '../../../Atoms/NewButton'

type Props = {
  box?: boolean
  buttonText: PageLayoutBlockButton['data']['text']
  buttonLink: PageLayoutBlockButton['data']['href']
  buttonTextColor?: PageLayoutBlockButton['data']['color']
  buttonColor?: PageLayoutBlockButton['data']['backgroundColor']
  className?: string
}

const ButtonBlock: React.FC<Props> = ({
  box,
  buttonText,
  buttonLink,
  buttonTextColor,
  buttonColor,
  className,
}) => (
  <Button
    variant="primary"
    box={box}
    href={buttonLink as string}
    color={buttonColor as string}
    textColor={buttonTextColor as string}
    className={classNames(
      'mx-auto py-6 px-8 bg-theme-primary sm:!text-lg !text-xs w-full',
      className
    )}
  >
    {buttonText as string}
  </Button>
)

export default ButtonBlock
