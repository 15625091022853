/* eslint-disable @next/next/no-img-element */

import { ProgressResponse } from '@betablocks/shared/lib/api/pelxp'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import useApi from '../../../hooks/useApi'
import Button from '../../Atoms/NewButton'

type Props = {
  title: string
  subtitle: string
  milestones: {
    amount: number
    image: string
    label: string
    description: string
  }[]
}

const PurchaseProgress: React.FC<Props> = ({ title, subtitle, milestones }) => {
  const api = useApi()

  const [progressData, setProgressData] = useState<ProgressResponse>({
    percentage: 0,
    achieved: 0,
    total: 0,
  })

  useEffect(() => {
    api.pelxp.getProgress().then((res) => {
      setProgressData(res)
    })
  }, [api.pelxp])

  return (
    <div className="flex flex-col items-center w-full mx-auto px-10 py-10 bg-white rounded-lg max-w-[1440px] ">
      <h3 className="text-4xl md:text-[60px] leading-tight font-bold max-w-[650px] mb-5 text-center">
        {title}
      </h3>
      <p className="md:text-[30px] text-lg text-center mb-[300px] max-w-[620px]">{subtitle}</p>
      <div className="flex  flex-col  items-center  w-full relative pt-10 ">
        {/* Progress Bar */}
        <div className="h-[42px] w-full bg-gray-200 rounded-full overflow-hidden ">
          <div
            className="h-full rounded-full flex items-center  "
            style={{
              width: progressData.percentage + '%',
              backgroundImage: 'linear-gradient(90deg, #A615FF, #FF00B8)',
            }}
          ></div>
        </div>
        <p
          className={classNames('z-20 absolute  text-[18px] font-bold top-[47px] left-[10px] ', {
            'text-white': progressData.percentage >= 15,
            'text-black': progressData.percentage < 15,
          })}
        >
          ${progressData.achieved.toFixed(2)}K <span className="font-normal text-sm">achieved</span>
        </p>

        {/* Milestones Markers */}
        {milestones.map((milestone, index) => {
          const milestonePosition = `${index * (50 / (milestones.length - 1)) + 15}%`
          return (
            <div
              key={index}
              className="flex flex-col items-center  gap-14 absolute top-[-197px] "
              style={{ left: milestonePosition }}
            >
              {/* Milestone Image */}
              <div className="flex flex-col items-center gap-0  ">
                <img
                  src={milestone.image}
                  alt={milestone.label}
                  className="md:w-[250px] h-[150px] rounded-lg"
                />
                <p className="text-[14px] max-w-[250px] h-[28px] text-center mt-2 text-[#8C8C8C]">
                  {milestone.description}
                </p>
              </div>
              <hr className="border-[1px] border-dashed w-[70px] rotate-90  border-[#000000a4]" />

              {/* Milestone Value */}
              <span className="text-[25px] font-bold   ">{milestone.label}</span>
            </div>
          )
        })}
      </div>
      <Button className="text-black border-2 font-bold border-black px-10 py-5 mt-[140px] rounded-xl text-lg h-[66px] w-[175px] mx-auto">
        Join now
      </Button>
    </div>
  )
}

export default PurchaseProgress
